<template>
  <el-dialog title="资金使用流水" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-row :gutter="20">
      <el-col :span="7">
        <span class="demonstration">借走状态:</span>
        <el-select v-model="queryParam.refTwoType" placeholder="请选择">
          <el-option
            v-for="item in optionss"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="primay" @click="selectFundLogButton()">查询</el-button>
        <el-button type="primay" @click="resettingButton">重置</el-button>
      </el-col>
    </el-row>
    <el-table :data="BankLogFrom" border stripe style="width: 100%" show-summary>
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column label="交易时间" prop="recordTime" />
      <el-table-column label="支行名称" prop="subbranch" />
      <el-table-column label="二级交易类型" prop="refTwoType" />
      <el-table-column label="交易前的金额" prop="beforeMoney" />
      <el-table-column label="本次交易金额" prop="money" />
      <el-table-column label="交易后金额" prop="afterMoney" />
      <el-table-column label="系统备注" prop="sysRemark" />
      <el-table-column label="备注" prop="remark" />
      <el-table-column label="创建时间" prop="sysCreated" />
      <el-table-column label="创建人" prop="sysCreatedBy" />
    </el-table>
  </el-dialog>
</template>
<script>
import { selectBankLogs } from "@/api/capital/bank/bank";
export default {
  name: "BankLog",
  data() {
    return {
      dialogVisible: false,
      BankLogFrom: [],
      queryParam: {},
      ids: null,
      optionss: [
        {
          value: "借用",
          label: "借用"
        },
        {
          value: "退还",
          label: "退还"
        }
      ]
    };
  },
  methods: {
    //打开弹窗
    show(id) {
      console.log(id);
      this.dialogVisible = true;
      this.ids = id;
      console.log(this.ids);
      this.selectFundLogButton();
    },
    selectFundLogButton() {
      this.queryParam.id = this.ids;
      console.log(this.queryParam.refTwoType);
      selectBankLogs(this.queryParam).then(res => {
        this.BankLogFrom = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    resettingButton() {
      this.queryParam = {};
      this.selectFundLogButton();
    }
  }
};
</script>